function createToastContainer() {
    let container = document.getElementById('toast-container');
    if (!container) {
        container = document.createElement('div');
        container.id = 'toast-container';
        container.className = 'fixed top-20 right-20 flex flex-col items-end space-y-2 z-50';
        document.body.appendChild(container);
    }
    return container;
}

export function showToast(message, type = 'info') {
    const container = createToastContainer();

    // Create the main toast element
    const toast = document.createElement('div');
    toast.className = 'flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800';
    toast.setAttribute('role', 'alert');

    // Create the icon container
    const iconContainer = document.createElement('div');
    iconContainer.className = 'inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg';

    // Set icon and colors based on type
    let iconSvg = '';
    switch (type) {
        case 'success':
            iconContainer.className += ' text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-200';
            iconSvg = '<svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20"><path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/></svg>';
            break;
        case 'error':
            iconContainer.className += ' text-red-500 bg-red-100 dark:bg-red-800 dark:text-red-200';
            iconSvg = '<svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20"><path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/></svg>';
            break;
        default:
            iconContainer.className += ' text-orange-500 bg-orange-100 dark:bg-orange-700 dark:text-orange-200';
            iconSvg = '<svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20"><path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"/></svg>';
    }
    iconContainer.innerHTML = iconSvg;

    // Create the message container
    const messageContainer = document.createElement('div');
    messageContainer.className = 'ms-3 text-sm font-normal';
    messageContainer.textContent = message;

    // Create the close button
    const closeButton = document.createElement('button');
    closeButton.className = 'ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700';
    closeButton.setAttribute('aria-label', 'Close');
    closeButton.innerHTML = '<span class="sr-only">Close</span><svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/></svg>';

    // Add event listener to close button
    closeButton.addEventListener('click', () => {
        removeToast(toast);
    });

    // Assemble the toast
    toast.appendChild(iconContainer);
    toast.appendChild(messageContainer);
    toast.appendChild(closeButton);

    // Add the toast to the container
    container.appendChild(toast);

    // Automatically remove the toast after 5 seconds
    setTimeout(() => {
        removeToast(toast);
    }, 5000);
}

export function removeToast(toast) {
    toast.classList.add('opacity-0', 'transition-opacity', 'duration-300');
    setTimeout(() => {
        toast.remove();
        // Remove container if it's empty
        const container = document.getElementById('toast-container');
        if (container && container.children.length === 0) {
            container.remove();
        }
    }, 300);
}
